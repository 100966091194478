/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import { AnimatePresence, m } from "framer-motion";
import { useAppState } from "@state";
import useLgcaAcFooterData from "@staticQueries/lgcaAc/FooterQuery";
import classNames from "classnames";
import loadable from "@loadable/component";
import filterActions from "@utils/filterActions";

const Cta = loadable(() => import("@organisms/Cta"));
const LgcaAcFooter = loadable(() => import("@organisms/LgcaAcFooter"));

const PageContainer = ({ children, className, path }) => {
  const [{ layout, ea }] = useAppState();
  const { footerNav, actions } = useLgcaAcFooterData();

  const upNext = filterActions(actions, ea.activistCodes || []);

  const action = upNext.length >= 1 ? upNext[0] : actions[actions.length - 1];
  const { theme, template } = layout;
  const isAction = template === "action";

  const entered = true;

  return (
    <m.div
      key={path}
      id="ac-layout"
      initial={{ opacity: 0, y: "10rem" }}
      animate={{ opacity: 1, y: "0rem" }}
      exit={{ opacity: 0, y: "-10rem" }}
      transition={{ duration: 0.5 }}
      className={`
      ${theme === "dark" ? "bg-brown" : "bg-cream"}
      ${!entered ? "opacity-0" : ""}
      flex min-h-screen w-full flex-grow flex-col`}
    >
      <main className={classNames("flex flex-grow flex-col", className)}>
        <AnimatePresence initial>{children}</AnimatePresence>
      </main>
      <footer>
        {!isAction && action?.[0]?.url && (
          <div>
            <Cta
              heading={action.heading}
              copy={action.copy}
              icon="buffalo"
              backgroundImage={action.backgroundImage}
              action={action}
              showAction
              path={path}
            />
          </div>
        )}
        <LgcaAcFooter nav={footerNav} />
      </footer>
    </m.div>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
