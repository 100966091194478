const filterActions = (actions, codes) => {
  if (codes && actions) {
    return actions.filter(action =>
      action.activistCodesExclusive
        ? !action.activistCodesExclusive.find(code => codes.includes(code))
        : true
    );
  }
  return actions;
};

export default filterActions;
